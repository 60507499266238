import { template as template_0a5a10d34a2749ae966dd91402f92b4c } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_0a5a10d34a2749ae966dd91402f92b4c(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
