import { template as template_c468363ca84f4c439dc83aee34ea2ab2 } from "@ember/template-compiler";
const FKText = template_c468363ca84f4c439dc83aee34ea2ab2(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
