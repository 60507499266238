import { template as template_f2365f00d1ab4acba286535b6c0753e5 } from "@ember/template-compiler";
const WelcomeHeader = template_f2365f00d1ab4acba286535b6c0753e5(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
