import { template as template_c00e2a7067af40c7bea2027b12386798 } from "@ember/template-compiler";
const FKLabel = template_c00e2a7067af40c7bea2027b12386798(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
