import { template as template_ca8d62cd39f94aefb2c8b96e45a63a99 } from "@ember/template-compiler";
const FKControlMenuContainer = template_ca8d62cd39f94aefb2c8b96e45a63a99(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
